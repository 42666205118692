import eventMixin from "./eventMixin"
import elementMatches from "./elementMatches"

const disableAttribute = "data-billing-form-disable"
const disableWithAttribute = "data-billing-form-disable-with"

class Form {
  static get events() {
    if (this._events === undefined) {
      this._events = {
        submit: "billing:form:submit",
        success: "billing:form:success",
        error: "billing:form:error",
        complete: "billing:form:complete",
        disable: "billing:form:disable",
        enable: "billing:form:enable"
      }
    }
    return this._events
  }

  static init(selector) {
    document.querySelectorAll(selector).forEach((element) => {
      new this(element)
    })
  }

  constructor(element) {
    this.element = element
    this.eventElement = this.element
    this.elementsEnabled = true
    this.submitEnabled = true
    this.submitter = undefined

    if (!this.element["_billing"]) {
      this.init()
    }
  }

  // All form subclasses first need to call 'super' in init() method.
  init() {
    this.addEventListener("submit", (...args) => {
      this.submitter = event.submitter || undefined

      this.stopSubmitIfDisabled(...args)
    }, { capture: true })

    // Turbo enables the submit button after form submit, no way to opt out:
    // https://github.com/hotwired/turbo/blob/f88bfe45ed44abb6ea9140a8ba138dfe68aa2730/src/core/drive/form_submission.js#L165-L168
    // Our workaround: immediately re-disable the submit element.
    this.addEventListener("turbo:submit-end", (event) => {
      if (this.submitter) {
        this._disableElement(this.submitter)
      }
    })
    this.element["_billing"] = this
  }

  get dataset() {
    return this.element.dataset
  }

  get elements() {
    return Array.from(this.element.elements)
  }

  handleSubmit() {
    this.disableElements()

    this.fire("submit")
  }

  handleSuccess() {
    // Q: why arent form elements enabled on success (enableElements)?
    // A: all forms redirect to another page on form success, so there's no
    // reason to enable elements after form success and before the redirect is
    // completed.
    this.fire("success")
    this.fire("complete")
  }

  handleError() {
    this.enableElements()

    this.fire("error")
    this.fire("complete")
  }

  // Elements disable/enable methods.
  get elementsDisabled() {
    return !this.elementsEnabled
  }

  disableElements() {
    if (this.elementsEnabled) {
      this.elementsEnabled = false

      this._disableElements()
      this.fire("disable")
    }
  }

  enableElements() {
    if (this.elementsDisabled) {
      this.elementsEnabled = true

      this._enableElements()
      this.fire("enable")
    }
  }

  // Disables form element with 'disable*' attrs.
  _disableElements() {
    this.elements.forEach(this._disableElement)
  }

  _disableElement(element) {
    if (element.getAttribute(disableAttribute)) {
      element.disabled = true
    } else if (element.getAttribute(disableWithAttribute)) {
      // Replacement text may already be set on an element, don't overwrite!
      // This happens when turbo enables the submit button before time.
      if (!element["billing:form:enable-with"]) {
        // Similar logic as in rails:
        // https://github.com/rails/rails/blob/de53ba56cab69fb9707785a397a59ac4aaee9d6f/actionview/app/assets/javascripts/rails-ujs/features/disable.coffee
        const replacement = element.getAttribute(disableWithAttribute)
        if (replacement) {
          if (elementMatches(element, "button")) {
            element["billing:form:enable-with"] = element.innerHTML
            element.innerHTML = replacement
          } else {
            element["billing:form:enable-with"] = element.value
            element.value = replacement
          }
        }
      }
      element.disabled = true
    }
  }

  _enableElements() {
    this.elements.forEach(this._enableElement)
  }

  _enableElement(element) {
    if (element.getAttribute(disableAttribute)) {
      element.disabled = false
    } else if (element.getAttribute(disableWithAttribute)) {
      const original = element["billing:form:enable-with"]
      if (original) {
        if (elementMatches(element, "button")) {
          element.innerHTML = original
        } else {
          element.value = original
        }
      }
      element.disabled = false
      // cleanup
      element["billing:form:enable-with"] = null
    }
  }

  // Submit disable/enable methods.
  get submitDisabled() {
    return !this.submitEnabled
  }

  get submitElements() {
    return this.elements.filter((element) => element.type === "submit")
  }

  disableSubmit() {
    if (this.submitEnabled) {
      this.submitEnabled = false

      // Form submitting is disabled via 'stopSubmitIfDisabled'.
      this._disableSubmitElements()
    }
  }

  enableSubmit() {
    if (this.submitDisabled) {
      this.submitEnabled = true

      this._enableSubmitElements()
    }
  }

  _disableSubmitElements() {
    this.submitElements.forEach((submitElement) => {
      submitElement.disabled = true
    })
  }

  _enableSubmitElements() {
    this.submitElements.forEach((submitElement) => {
      submitElement.disabled = false
    })
  }

  isRailsUjsLoaded() {
    return !!window._rails_loaded
  }

  isRailsUjs() {
    return this.isRailsUjsLoaded() &&
      this.dataset.remote &&
      this.dataset.remote !== "false"
  }

  isTurboLoaded() {
    return !!window.Turbo
  }

  isTurbo() {
    return this.isTurboLoaded() && this.dataset.turbo !== "false"
  }

  isNative() {
    return !(this.isRailsUjs() || this.isTurbo())
  }

  // private
  stopSubmitIfDisabled(event) {
    if (this.disabled || this.submitDisabled) {
      event.preventDefault()
      event.stopPropagation()
      return false
    }
  }
}

Object.defineProperties(
  Form.prototype,
  Object.getOwnPropertyDescriptors(eventMixin)
);

export default Form
